<div id="main-content" class="container mt-5 flex-grow-1" *ngIf="(auth.isAuthenticated$ | async) === true">
  You are authenticated!!!
</div>
<div id="main-content" class="container mt-5 flex-grow-1" *ngIf="(auth.isAuthenticated$ | async) === false">

  <div id="content-home" class="page">
    <div class="text-center">
      <h1 class="mb-4 unauth auth-invisible">Passwordless logins Demo</h1>
      <p class="unauth auth-invisible">
        Use the links below to try out different types of Passwordless options available in Okta CIC/Auth0
      </p>
    </div>

    <div class="auth-invisible" style="text-align: center !important;">
      <br>
      <br>
      <br>
      <div class="row">
        <div class="col-sm-12" style="align-items: center !important;">
          <div class="nav-item auth-invisible">
            <button id="emailLogin" (click)="loginWithRedirectEmail()"
              class="btn btn-primary btn-margin btn-lg auth-invisible hidden">
              Try Passwordless login using Email
            </button>
          </div>
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col-sm-12">
          <div class="nav-item auth-invisible">
            <button id="smsLogin" (click)="loginWithRedirectSMS()" class="btn btn-primary btn-margin btn-lg auth-invisible hidden">
              Try Passwordless login using Phone
            </button>
          </div>
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col-sm-12">
          <div class="nav-item auth-invisible">
            <button id="passkeysLogin" (click)="loginWithRedirectPasskeys()"
              class="btn btn-primary btn-margin btn-lg auth-invisible hidden">
              Try Passwordless login using Passkeys
            </button>
          </div>
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col-sm-12">
          <div class="nav-item auth-invisible">
            <button id="qsLoginBtn" (click)="loginWithRedirect()" class="btn btn-primary btn-margin btn-lg auth-invisible hidden">
              Try Login using Username/Email and Password
            </button>
          </div>
        </div>
      </div>
      <br>
    </div>
  </div>
</div>