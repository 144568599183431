import { Component } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.css']
})

export class HeroComponent {
  constructor(
    public auth: AuthService
  ) {}

  emailOptions = {
    authorizationParams: {
      scope: 'openid profile email',
      connection: 'email'
    }
  }

  smsOptions = {
    authorizationParams: {
      scope: 'openid profile email',
      connection: 'sms'
    }
  }

  passkeysOptions = {
    authorizationParams: {
      scope: 'openid profile email',
      connection: 'PasswordlessCOI'
    }
  }

  loginWithRedirect() {
    this.auth.loginWithRedirect();
  }

  loginWithRedirectEmail() {
    this.auth.loginWithRedirect(this.emailOptions);
  }

  loginWithRedirectSMS() {
    this.auth.loginWithRedirect(this.smsOptions);
  }

  loginWithRedirectPasskeys() {
    this.auth.loginWithRedirect(this.passkeysOptions);
  }  
}
